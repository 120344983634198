<template>
	<div class="notice_popup human int">
		<div class="close">
			<img src="/images/close.png" alt="닫기" @click="$emit('close')"/>
		</div>
		<div class="tit">회원탈퇴를 하시겠습니까?</div>
		<div class="member">비밀번호 입력 후 회원탈퇴가 가능합니다.</div>
		<div class="inputlist">
			<span>*</span> 비밀번호<InputComp type="password" v-model="password" placeholder="비밀번호를 입력하세요!" title="비밀번호" :rules="{'required':true, 'minLength':8, 'maxLength':16}" :showError="false" maxlength="16" />
			<br/><div class="error" v-if="password.message" style="color:red;">{{password.message}}</div>
		</div>
		<div class="select-btn">
			<div class="btn" @click="$emit('close')">취소</div>
			<div class="btn" @click="setMberWithdrawal()">확인</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		param : Object,
	},
	
	data() {
		return {
			data : this.param,

			password : ''
		}
	},

	mounted() {
		//console.log('param', this.param);
	},

	methods : {
		setMberWithdrawal() {
			var param = this.param;
			param.password = this.password;

			//console.log('setMberWithdrawal PARAM', param);

			this.$.httpPost('/api/mem/myp/setMberWithdrawal', param)
				.then(() => {
					alert('회원탈퇴 되었습니다.');
					
					this.$.logout('MEM005M01');
					this.$emit('close');
				})
				.catch(this.$.httpErrorCommon);
		}
	}
}
</script>

<style scoped>
.error {
	font-size: 80%;
	color:red;
	position : absolute;
    margin-left: 86px;
	width:200%;
}
</style>